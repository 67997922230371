/**
 * break points
 * most calculations in javascript are in pixels.  If you are binding a value
 * to the style attribute in jsx please use relative units.  The `pxToRem`
 * helper function can be useful in these scenarios
 */

// BREAKPOINTS BASED AROUND TACHYONS -------------------------------------------

/**
 * 480px (30rem)
 */
export const SMALL_MAX_PX = 767;

/**
 * 481px (30.0625rem)
 */
export const MEDIUM_MIN_PX = 768;

/**
 * p1024px (64rem)
 */
export const MEDIUM_MAX_PX = 1024;

/**
 * 1025px (64.0625rem)
 */
export const LARGE_MIN_PX = 1025;

/**
 * maximum width of the page conatiner (80rem);
 */
export const MAX_PAGE_WIDTH = 1280;

// VARIOUS DEVICE WIDTHS -------------------------------------------------------

/**
 * 375px (23.4375rem)
 */
export const IPHONE_X_WIDTH = 375;

/**
 * 768px (48rem)
 */
export const IPAD_WIDTH_PORTRAIT = 768;

/**
 * 1366px (85.375rem)
 */
export const IPAD_PRO_WIDTH_LANDSCAPE = 1366;
