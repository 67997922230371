import { type FunctionComponent } from 'react';
import { type ContentCardCommonFieldsFragment } from '../../../__generated__/graphql-client-types';
import { type ProductCardContent } from '../../../types/construct.types';
import { ArticleProductCard, DoorBusterProductCard } from '../../content-components/product-card/product-card.component';

export type ProductContentCardProps = { cardContent: ContentCardCommonFieldsFragment; isSaleCard?: boolean };

export const ProductContentCard: FunctionComponent<ProductContentCardProps> = ({ cardContent, isSaleCard = false }) =>
	isProductContent(cardContent) ? (
		<>
			{isSaleCard ? (
				<div className="w-100 w-25-ns pa2">
					<DoorBusterProductCard product={cardContent} />
				</div>
			) : (
				<div className="w-25-m pa2">
					<ArticleProductCard product={cardContent} />
				</div>
			)}
		</>
	) : null;

function isProductContent(content: ContentCardCommonFieldsFragment): content is ProductCardContent {
	return Boolean(content.detail?.__typename === 'ContentProductCardDetail');
}
