import { type FunctionComponent } from 'react';
import { type ImageFieldsFragment } from '../../../../__generated__/graphql-client-types';
import { MAX_PAGE_WIDTH, MEDIUM_MAX_PX, SMALL_MAX_PX } from '../../../../constants/breakpoints';
import { CloudinaryImage, type CloudinaryImageProps } from '../../cloudinary-image/cloudinary-image.component';
export type PartialWidthGraphImageProps = {
	image: ImageFieldsFragment;
	caption?: string;
	perRow?: number;
};
export const PartialWidthGraphImage: FunctionComponent<PartialWidthGraphImageProps> = ({
	image: { imageType: type, id: publicID, description },
	caption,
	perRow = 1
}) => {
	const widthRatio = 1 / perRow;
	const widthRatioPercent = Math.round(widthRatio * 100);
	const width = widthRatio * MAX_PAGE_WIDTH;
	const variations = [
		{ mediaCondition: { maxWidth: SMALL_MAX_PX }, options: { width: widthRatio * SMALL_MAX_PX } },
		{ mediaCondition: { maxWidth: MEDIUM_MAX_PX }, options: { width: widthRatio * MEDIUM_MAX_PX } }
	];
	const imageProps: CloudinaryImageProps = {
		options: { width, type, crop: 'fit' },
		description,
		publicID,
		variations
	};
	const style = {
		flexBasis: `${widthRatioPercent}%`
	};
	const classes = perRow > 1 ? 'ph2' : '';
	return (
		<section style={style} className={classes}>
			<CloudinaryImage {...imageProps} />
			{caption && <div className="f5 tc">{caption}</div>}
		</section>
	);
};
