import { type ObjectContent, type ListContentOf, type CardContent, type ContentComponent } from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';

export interface GroupItemsSectionContent extends ObjectContent {
	fields: {
		group_items: ListContentOf<CardContent>;
	};
}

export const GroupItemsSection: ContentComponent<GroupItemsSectionContent> = ({ content, renderer }) => (
	<div className="flex flex-wrap items-start">
		{content.fields.group_items.items.map((item, itemIndex) =>
			renderer.render(item, itemIndex, { GroupItemContent: { totalItemCount: content.fields.group_items.items.length } })
		)}
	</div>
);

addContentHandler('group-items-section', 'ObjectContent', 'group-items-section', GroupItemsSection);
