import { type FunctionComponent } from 'react';
import { SUPPORT_EMAIL_US_LINK } from '../../../constants/links';
import { useContactInfo } from '../../../hooks/apollo/site/site.hooks';
import { StyledLink } from '../../common-components/link/styled-link.component';

export const ContactHours: FunctionComponent = () => {
	const { phone, chat } = useContactInfo();
	return (
		<div className="f6 lh-copy">
			<div className="b f4 f6-ns mb2 mb0-ns">
				<span className="dib dn-ns mr2">Call us at </span>
				<span>{phone.number}</span>
			</div>
			<div className="b">Phone Support</div>
			<div>
				<span>M-F: </span>
				<span>
					{phone.weekdayHours.startTime} - {phone.weekdayHours.endTime} PST
				</span>
			</div>
			<div>
				<span>Sat: </span>
				<span>
					{phone.weekendHours.startTime} - {phone.weekendHours.endTime} PST
				</span>
			</div>
			{phone.exceptions.map((exception, index) => (
				<div key={index}>
					<span>{exception.date}: </span>
					<span>
						{exception.fullDayClosure ? (
							<>Closed</>
						) : (
							<>
								{exception.adjustedHours?.startTime} - {exception.adjustedHours?.endTime} PST
							</>
						)}
					</span>
				</div>
			))}
			<div className="b mt3">Chat Support</div>
			<div>
				<span>M-F: </span>
				<span>
					{chat.weekdayHours.startTime} - {chat.weekdayHours.endTime} PST
				</span>
			</div>
			<div>
				<span>Sat: </span>
				<span>
					{chat.weekendHours.startTime} - {chat.weekendHours.endTime} PST
				</span>
			</div>
			{chat.exceptions.map((exception, index) => (
				<div key={index}>
					<span>{exception.date}: </span>
					<span>
						{exception.fullDayClosure ? (
							<>Closed</>
						) : (
							<>
								{exception.adjustedHours?.startTime} - {exception.adjustedHours?.endTime} PST
							</>
						)}
					</span>
				</div>
			))}
			<div className="mt3">
				<StyledLink url={SUPPORT_EMAIL_US_LINK} underlineHover={true}>
					Contact Us
				</StyledLink>
			</div>
		</div>
	);
};
