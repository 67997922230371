import { type ContentComponent, type EmailSubscribeFormPlaceholderContent } from '../../../../types/construct.types';
import { Divider } from '../../../common-components/divider/divider.component';
import { addContentHandler } from '../add-content-handler';

export const HorizontalDividerPlaceholder: ContentComponent<EmailSubscribeFormPlaceholderContent> = () => (
	<div className="w-100">
		<Divider horizontalSpacing={0} verticalSpacing={4} />
	</div>
);

addContentHandler('content-support-pages', 'ObjectContent', 'placeholder-horizontal-divider', HorizontalDividerPlaceholder);
