/**
 * Removes any non-digit characters from the input string. Returns a new string.
 */
export const filterOnlyDigits = (input?: string): string => {
	if (!input) return '';
	return input.replace(/\D/g, '');
};

/**
 * Builds delimited strings.
 * Allows adding new members to the list retroactivly.
 * Use Cases:
 *  - className
 *  - CSV data
 *  - analytics strings
 */
export class DelimitedString {
	private delimeter: string;
	private members: string[];

	constructor(initialString: string, delimeter = ',') {
		this.delimeter = delimeter;
		this.members = this.parse(initialString);
	}

	public push(input: string) {
		this.members.push(input);
	}

	public toString() {
		return this.members.join(this.delimeter);
	}

	private parse(input: string): string[] {
		return input.split(this.delimeter);
	}
}
