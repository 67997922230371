import { type FunctionComponent } from 'react';
import { getTabStyles } from '../../../../helpers/content-helper/content.helper';
import { type ContentComponent, type ContentComponentProps, type GeneralContentGroupContent } from '../../../../types/construct.types';
import { ResponsiveTab, ResponsiveTabGroup } from '../../../common-components/responsive-tabs/responsive-tabs.component';
import { addContentHandler } from '../add-content-handler';
import { largeTab } from './general-content-group.css';

const COL_PREFIX = 'col_';
const COL_WIDTH_MAP = {
	1: 100,
	2: 50,
	3: 33,
	4: 25
};

type ColumnGroupProps = { colCount: number; group: ContentComponentProps<GeneralContentGroupContent> };

const ColumnGroup: FunctionComponent<ColumnGroupProps> = ({ colCount, group: { content, renderer, options } }) => (
	<div className="flex flex-column flex-row-ns">
		{content.fields.contentGroups.items.map((item, itemIndex) => (
			<div className={`w-${COL_WIDTH_MAP[colCount]}-ns ph1 mt2 mt0-ns`} key={itemIndex}>
				{renderer.render(item.fields.sections, undefined, options)}
			</div>
		))}
	</div>
);

type TabGroupProps = {
	startingTabName: string;
	group: ContentComponentProps<GeneralContentGroupContent>;
};
const TabGroup: FunctionComponent<TabGroupProps> = ({ startingTabName, group: { content, renderer, options } }) => (
	<ResponsiveTabGroup
		startingTabName={startingTabName}
		tabGroupClasses="bb bn-ns b--theme-grey-light"
		tabListClasses="justify-center-ns bb-ns b--theme-grey-lighter"
		testId="general-content-tabs">
		{content.fields.contentGroups.items.map((item, itemIndex) => {
			const tabClasses = getTabStyles(itemIndex, content.fields.contentGroups.items.length);
			return (
				<ResponsiveTab
					tabClasses={`${tabClasses} ${largeTab}`}
					tabContentClasses="tc2-title justify-center-ns tc-ns"
					activeTabClasses={`bg-theme-grey-lighter bg-theme-white-ns bb-0`}
					inactiveTabClasses="bg-theme-grey-lighter bb-0"
					name={item.fields.title || ''}
					key={`${item.fields.title}-${itemIndex}`}>
					<div className="pa2 bl br ba-ns br--bottom-ns br2-ns b--theme-grey-light">
						{item.fields.sections.items.map((sectionItem, sectionItemIndex) =>
							renderer.render(sectionItem, sectionItemIndex, options)
						)}
					</div>
				</ResponsiveTab>
			);
		})}
	</ResponsiveTabGroup>
);

export const GeneralContentGroup: ContentComponent<GeneralContentGroupContent> = (group) => {
	const { content } = group;
	const firstTabTitle = content.fields.contentGroups.items[0]?.fields.title ?? '';
	if (firstTabTitle.startsWith(COL_PREFIX)) {
		// TODO: SODEV-39301 - need a better mechanism to determine column vs. tab layout
		return <ColumnGroup colCount={content.fields.contentGroups.items.length} group={group} />;
	}
	return <TabGroup startingTabName={firstTabTitle} group={group} />;
};

addContentHandler('content-common', 'ObjectContent', 'general-content-group', GeneralContentGroup);
