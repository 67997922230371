import { type ContentComponent, type ContentSectionContent } from '../../../../types/construct.types';
import { BackgroundImage } from '../../../images/background-image/background-image.component';
import { addContentHandler } from '../add-content-handler';
import { CallToAction } from '../call-to-action/call-to-action.handler';
import { RichText } from '../rich-text/rich-text.handler';
import { contentImage, contentImageHeight, contentRichText, evenNumberLayout, oddNumberLayout } from './content-section.css';

export const ContentSection: ContentComponent<ContentSectionContent> = ({ content, index, options, renderer }) => {
	const { fields } = content;
	const image = fields.media_image?.image;
	const isEven = (index || 0) % 2 === 0;
	const layout = isEven ? evenNumberLayout : oddNumberLayout;
	const contentSectionClasses = `${layout} w-100 mt3-ns`;
	const cta = fields.cta;
	const richText = fields.body_copy?.richText || '';
	const titleText = fields.heading || '';
	const contentVideo = fields.media_video?.items[0];

	return (
		<>
			<div className={contentSectionClasses} data-testid="content-section">
				<>
					{titleText && (
						<div className={`pt1-ns dn-ns`}>
							<h2 className="f4 ph4">{titleText}</h2>
						</div>
					)}
					{image && (
						<div className={`${contentImage} relative`}>
							<BackgroundImage
								publicID={image.id}
								options={{
									width: 500,
									height: 444,
									crop: 'lfill',
									gravity: 'custom'
								}}
								className={`bg-center relative cover ${contentImageHeight}`}>
								{contentVideo && renderer.render(fields.media_video, 0, { ...options, ContentSection: true })}
							</BackgroundImage>
						</div>
					)}
					<div className={`bb b--theme-grey-light bn-ns ${contentRichText} pv4 ph3-ns`}>
						{titleText && <h2 className="f4 ph4 pt1-ns dn db-ns">{titleText}</h2>}
						{richText && <RichText className="ph4 lh-copy" rawHtml={richText} />}
						<div className="mb3 ph4">{cta && <CallToAction content={content} index={index} renderer={renderer} />}</div>
					</div>
				</>
			</div>
		</>
	);
};
addContentHandler('content-section', 'ObjectContent', 'content-section', ContentSection);
