import { type FunctionComponent } from 'react';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';

export type PanelComponentProps = {
	children: JSX.Element;
	id?: string;
	className?: string;
	headerClassName?: string;
	containerClassName?: string;
	headingContent?: string | JSX.Element;
	automationHook?: string;
};

export const PanelComponent: FunctionComponent<PanelComponentProps> = ({
	children,
	id,
	className = 'f3',
	headerClassName = '',
	containerClassName = 'pa3',
	headingContent,
	automationHook
}) => {
	return (
		<section
			id={id}
			className={`w-100 ba b--theme-grey-light ${className}`}
			data-automation={generateDataSelector('panel', automationHook)}>
			<div className={`flex items-center pv3 ${headerClassName}`}>
				{typeof headingContent === 'string' ? <h2 className="mv0 mh2 lh-title f-inherit">{headingContent}</h2> : headingContent}
			</div>
			<div className="bb b--theme-grey-light" />
			<div className={`${containerClassName}`}>{children}</div>
		</section>
	);
};
