import gql from 'graphql-tag';
import { ImageFields, VideoFields, LinkFields } from '../common-fields.queries';

const FacetItemFields = gql`
	fragment FacetItemFields on FacetItem {
		id
		value
		groupName
		range {
			min
			max
		}
	}
`;

const AuthorProfileFields = gql`
	${ImageFields}

	fragment AuthorProfileFields on AuthorProfile {
		id
		name
		avatar {
			...ImageFields
		}
	}
`;

const BrandFields = gql`
	${ImageFields}
	fragment BrandFields on Brand {
		logo {
			...ImageFields
		}
		name
	}
`;

const ContentProductCardDetailFields = gql`
	${ImageFields}

	fragment ContentProductCardDetailFields on ContentProductCardDetail {
		finishId
		manufacturer
		description
		model
		finishes {
			id
			title
		}
		startingPrice
		averageRating
		reviewCount
	}
`;

// Create a fragment with the fields needed for the CardContent type.
const ContentCardCommonFields = gql`
	${AuthorProfileFields}
	${FacetItemFields}
	${ImageFields}
	${VideoFields}
	${LinkFields}
	${BrandFields}
	${ContentProductCardDetailFields}

	fragment ContentCardCommonFields on ContentCardValue {
		cardType
		image {
			...ImageFields
		}
		title
		description
		url
		trackingCode
		detail {
			... on ContentArticleCardDetail {
				video {
					...VideoFields
				}
			}
			... on ContentCategoryCardDetail {
				facets {
					...FacetItemFields
				}
			}
			... on ContentFavoriteCardDetail {
				author {
					...AuthorProfileFields
				}
				totalProducts
				productImages {
					...ImageFields
				}
			}
			... on ContentProductCardDetail {
				...ContentProductCardDetailFields
			}
			... on ContentProfileCardDetail {
				profile {
					...AuthorProfileFields
				}
			}
			... on ContentVideoCardDetail {
				video {
					...VideoFields
				}
			}
			... on ContentBrandCardDetail {
				brand {
					...BrandFields
				}
				link {
					...LinkFields
				}
			}
		}
	}
`;

const ContentCardValueFields = gql`
	${ContentCardCommonFields}

	fragment ContentCardValueFields on ContentCardValue {
		...ContentCardCommonFields
		fieldIndices
	}
`;

const ContentImageValueFields = gql`
	${ImageFields}

	fragment ContentImageValueFields on ContentImageValue {
		image {
			...ImageFields
		}
	}
`;

const ContentLinkValueFields = gql`
	fragment ContentLinkValueFields on ContentLinkValue {
		linkUrl
		trackingCode
	}
`;

const ContentListValueFields = gql`
	fragment ContentListValueFields on ContentListValue {
		itemIndices
	}
`;

const ContentObjectValueFields = gql`
	fragment ContentObjectValueFields on ContentObjectValue {
		fieldIndices
	}
`;

const ContentTextValueFields = gql`
	fragment ContentTextValueFields on ContentTextValue {
		text
	}
`;

const ContentRichTextValueFields = gql`
	fragment ContentRichTextValueFields on ContentRichTextValue {
		richText
	}
`;

// This is broken into fragments to make it easier to make client-friendly content structure types.
// See src/types/construct.types.ts.
export const ContentDocumentFields = gql`
	${ContentCardValueFields}
	${ContentImageValueFields}
	${ContentListValueFields}
	${ContentLinkValueFields}
	${ContentObjectValueFields}
	${ContentRichTextValueFields}
	${ContentTextValueFields}

	fragment ContentDocumentFields on ContentDocument {
		id
		rootIndex
		kinds
		fieldNames
		values {
			... on ContentCardValue {
				...ContentCardValueFields
			}
			... on ContentImageValue {
				...ContentImageValueFields
			}
			... on ContentLinkValue {
				...ContentLinkValueFields
			}
			... on ContentListValue {
				...ContentListValueFields
			}
			... on ContentObjectValue {
				...ContentObjectValueFields
			}
			... on ContentRichTextValue {
				...ContentRichTextValueFields
			}
			... on ContentTextValue {
				...ContentTextValueFields
			}
		}
	}
`;

export const SUPPORT_PAGE_DOCUMENTS = gql`
	${ContentDocumentFields}
	query SupportPageDocuments($input: PageDocumentCriteria!) {
		supportMenuDocument {
			...ContentDocumentFields
		}
		pageDocument(criteria: $input) {
			...ContentDocumentFields
		}
	}
`;

export const LANDING_PAGE = gql`
	${ContentDocumentFields}
	query LandingPage($input: PageDocumentCriteria!) {
		pageDocument(criteria: $input) {
			...ContentDocumentFields
		}
	}
`;
