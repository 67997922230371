import { useMutation } from '@apollo/client/react/hooks';
import { logError } from 'fergy-core-react-logging';
import { type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import {
	type CustomerContactRequest,
	type ContactUsRequestMutation,
	type ContactUsRequestMutationVariables
} from '../../../__generated__/graphql-client-types';
import { SUPPORT_EMAIL_ERROR, SUPPORT_EMAIL_SUCCESS } from '../../../constants/support';
import {
	emailInputRegistrationConfig,
	orderNumberInputRegistrationConfig
} from '../../../helpers/form-validation-helper/form-validation-helper';
import { useNotification } from '../../../hooks/notification/notification.hooks';
import { useRecaptcha } from '../../../hooks/recaptcha/recaptcha.hooks';
import { CONTACT_REQUEST } from '../../../queries/contact-us/contact-us.queries';
import { filterOnlyDigits } from '../../../utils/string/general.utils';
import { StyledButton } from '../../buttons';
import { Label } from '../../common-components/label/label.component';
import { PanelComponent } from '../../common-components/panel-component/panel-component.component';
import { Recaptcha } from '../../common-components/recaptcha/recaptcha.component';
import { TextInputWithLabel } from '../../inputs';
import { TextAreaWithLabel } from '../../inputs/text-area/text-area.component';
import { PhoneNumberInput } from '../../phone-number-input/phone-number-input.component';

const FIELD_STYLE = 'b pb4 w-100 w-50-ns';
const COL2_FIELD_STYLE = `${FIELD_STYLE} pl4-ns`;

type FormInputs = {
	orderNumber?: number;
	customerName: string;
	subject: string;
	comments: string;
	email: string;
	phone: string;
	hasOrderNo: string;
	contactBy: string;
	savedCartNumber: string;
};

export type ContactUsFormProps = {
	isSpecialPricing?: boolean;
};

export const ContactUsForm: FunctionComponent<ContactUsFormProps> = ({ isSpecialPricing = false }) => {
	const { getToken } = useRecaptcha();
	const [sendRequest] = useMutation<ContactUsRequestMutation, ContactUsRequestMutationVariables>(CONTACT_REQUEST);
	const { notifyDanger, notifySuccess } = useNotification();
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		reset,
		control
	} = useForm<FormInputs>({
		mode: 'onChange',
		defaultValues: {
			hasOrderNo: 'Yes',
			contactBy: 'Email'
		}
	});

	const onSubmit = async (formInput: FormInputs) => {
		try {
			const token = await getToken();

			const { customerName, email, phone, orderNumber, hasOrderNo, subject, comments, contactBy, savedCartNumber } = formInput;
			const hasOrderNoYesNo = hasOrderNo === 'Yes';
			const input: CustomerContactRequest = {
				fullName: customerName,
				emailAddress: email,
				phoneNumber: filterOnlyDigits(phone),
				orderNumber: orderNumber && hasOrderNoYesNo ? Number(orderNumber) : undefined,
				subject,
				comments,
				contactMethod: contactBy === 'Email' ? 'EMAIL' : 'PHONE',
				token,
				isSpecialPricing,
				savedCartNumber
			};

			const { data } = await sendRequest({
				variables: { input }
			});
			if (data?.contactUsRequest) {
				notifySuccess(SUPPORT_EMAIL_SUCCESS);
				reset();
			} else {
				notifyDanger(SUPPORT_EMAIL_ERROR);
			}
		} catch (error) {
			notifyDanger(SUPPORT_EMAIL_ERROR);
			logError(error);
		}
	};

	const hasOrderNumber = watch('hasOrderNo') === 'Yes';
	const contactByPhone = watch('contactBy') === 'Phone';
	const heading = isSpecialPricing ? 'Special Pricing Service Form' : 'Online Customer Service Form';

	return (
		<div className="relative">
			<PanelComponent headingContent={heading} headerClassName="f5">
				<form onSubmit={handleSubmit(onSubmit)} data-testid="email-us-form" aria-label="email us form">
					<div className="flex flex-wrap">
						<TextInputWithLabel
							className={FIELD_STYLE}
							label="Your Name"
							invalid={Boolean(errors.customerName)}
							invalidMessage={errors.customerName?.message}
							data-testid="customer-name-input"
							required={true}
							{...register('customerName', { required: 'Please enter your name.' })}
						/>
						{isSpecialPricing ? (
							<TextInputWithLabel
								className={COL2_FIELD_STYLE}
								label="Saved Cart Number"
								data-testid="saved-cart-input"
								{...register('savedCartNumber')}
							/>
						) : (
							<TextInputWithLabel
								className={COL2_FIELD_STYLE}
								label="Message Subject"
								required={true}
								invalid={Boolean(errors.subject)}
								invalidMessage={errors.subject?.message}
								{...register('subject', { required: 'Please enter a subject for this message.' })}
							/>
						)}
						<TextInputWithLabel
							className="b pb4 w-100 w-50-ns"
							label="Your Email Address"
							invalid={Boolean(errors.email)}
							invalidMessage={errors.email?.message}
							required={true}
							{...register('email', emailInputRegistrationConfig)}
						/>
						<div className={COL2_FIELD_STYLE}>
							<PhoneNumberInput
								formName="contact-us"
								name="phone"
								control={control}
								errors={errors}
								required={contactByPhone}
								testId="phone-input"
							/>
						</div>

						{!isSpecialPricing && (
							<div className="w-100 w-50-ns">
								<Label className={`${FIELD_STYLE} theme-primary`} label="Have an Order Number?">
									<>
										<label htmlFor="hasOrderNumber" className="f5 normal">
											<input id="hasOrderNumber" type="radio" value="Yes" {...register('hasOrderNo')} />
											Yes
										</label>

										<label htmlFor="hasNoOrderNumber" className="f5 normal">
											<input
												id="hasNoOrderNumber"
												type="radio"
												value="No"
												className="ml2"
												data-testid="no-order-number-input"
												required={true}
												{...register('hasOrderNo')}
											/>
											No
										</label>
									</>
								</Label>
								{hasOrderNumber && (
									<TextInputWithLabel
										className="b theme-primary pb4 w-100"
										label="Order Number"
										data-testid="order-number-input"
										invalid={Boolean(errors.orderNumber)}
										invalidMessage={errors.orderNumber?.message}
										required={true}
										{...register('orderNumber', orderNumberInputRegistrationConfig)}
									/>
								)}
							</div>
						)}

						<Label className={isSpecialPricing ? FIELD_STYLE : COL2_FIELD_STYLE} label="Contact me by" id="contactBy">
							<>
								<label htmlFor="contactByEmail" className="f6 normal">
									<input id="contactByEmail" type="radio" value="Email" {...register('contactBy')} />
									Email
								</label>
								<label htmlFor="contactByPhone" className="f6 normal">
									<input
										id="contactByPhone"
										type="radio"
										value="Phone"
										className="ml2"
										data-testid="contact-phone-input"
										{...register('contactBy')}
									/>
									Phone
								</label>
							</>
						</Label>
					</div>
					<TextAreaWithLabel
						labelClassName="b"
						label="Comments"
						required={true}
						invalid={Boolean(errors.comments)}
						invalidMessage={errors.comments?.message}
						{...register('comments', { required: 'Please enter a comment.' })}
					/>
					<div className="mt3">
						<StyledButton buttonType="submit">Send</StyledButton>
					</div>
					<Recaptcha />
				</form>
			</PanelComponent>
		</div>
	);
};
