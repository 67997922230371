import { type FunctionComponent, type PropsWithChildren } from 'react';
import { focusWithin, inputGroup } from './input-group.css';

export type InputGroupProps = {
	className?: string;
	showFocusWithin?: boolean;
};

export const InputGroup: FunctionComponent<PropsWithChildren<InputGroupProps>> = ({ className, showFocusWithin, children }) => {
	return (
		<div className={`${inputGroup} ${showFocusWithin ? focusWithin : ''} omni-input-group ${className ? className : ''}`}>
			{children}
		</div>
	);
};
