import { generateDataSelector } from '../../../../helpers/general-helper/general-helper';
import {
	type ObjectContent,
	type TextContent,
	type ListContentOf,
	type CardContent,
	type RichTextContent,
	type ContentComponent
} from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';

export type MediaGroupLayout = 'hero' | 'row-of-2' | 'row-of-3' | 'row-of-4' | 'row-of-5' | 'row-of-maximum';
export type MediaGroupItemArrangement =
	| 'card-image-title-description'
	| 'card-title-image-description'
	| 'bullet-image-title-description'
	| 'bullet-title-description-image';

export interface MediaGroupContent extends ObjectContent {
	fields: {
		layout: MediaGroupLayout;
		itemArrangement: MediaGroupItemArrangement;
		heading: TextContent;
		groupItems: ListContentOf<CardContent | CardContentRich>;
	};
}

export interface CardContentRich extends CardContent {
	title: string;
	description: string;
	cardFields: {
		richDescription: RichTextContent;
	};
}

export const MediaGroup: ContentComponent<MediaGroupContent> = ({ content, renderer }) => {
	const layout = content.fields.layout;
	const itemArrangement = content.fields.itemArrangement;
	return (
		<>
			{content.fields.heading && <h2>{content.fields.heading}</h2>}
			<div className="w-100 flex flex-wrap items-start" data-automation={generateDataSelector('section', content.fields.heading)}>
				{content.fields.groupItems.items.map((item, itemIndex) =>
					renderer.render(item, itemIndex, { CardContent: { itemArrangement, layout } })
				)}
			</div>
		</>
	);
};

addContentHandler('content-media-group', 'ObjectContent', 'media-group', MediaGroup);
