import { type FunctionComponent, type PropsWithChildren } from 'react';
import { type TachyonsSpacing } from '../../../types/general.types';
import { divider } from './divider.css';

export type DividerProps = {
	horizontalSpacing?: TachyonsSpacing;
	verticalSpacing?: TachyonsSpacing;
};

export const Divider: FunctionComponent<PropsWithChildren<DividerProps>> = ({ children, horizontalSpacing = 3, verticalSpacing = 3 }) => {
	const childSpacing = children ? `ph${horizontalSpacing}` : '';
	return (
		<div data-testid="divider" className={`${divider} ph${horizontalSpacing} pv${verticalSpacing} flex flex-row`}>
			<span className={`b theme-grey ${childSpacing}`}>{children}</span>
		</div>
	);
};
