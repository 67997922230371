import { type ProductStatusEnum } from '../../../client/__generated__/graphql-client-types';

/**
 * Apollo Cache requires conforming query variables when attempting to look-up
 * cache values. For example, the PDP which filters product variants by status
 * through either a query to field-level argument will have that status
 * incorporated into the cache key.
 *
 * These constants are reused across the following files / types of files:
 *
 * 	- Product Router Page
 * 	- Add to Cart Analytics Hook
 * 	- Client-side Mock Data for Stories / Hooks
 *
 * Updating the const values here should propagate to all relevant areas.
 */
export const DEFAULT_PDP_PRODUCT_STATUS: ProductStatusEnum[] = ['stock', 'discontinued', 'nonstock', 'pending'];

/**
 * Apollo Cache requires synchronization of query/field-level variables.
 * Use this const to keep relevant parts aligned.
 *
 * These constants are reused across the following files / types of files:
 *
 * 	- PLA Page
 * 	- Add to Cart Analytics Hook
 * 	- Client-side Mock Data for Stories / Hooks
 *
 * Updating the const values here should propagate to all relevant areas.
 */
export const DEFAULT_PLA_PRODUCT_STATUS: ProductStatusEnum[] = ['stock', 'discontinued', 'nonstock'];

/**
 * Product Reviews Section ID
 */
export const REVIEWS_SECTION_ID = 'reviews';

/**
 * Product Overview Section ID
 */
export const OVERVIEW_SECTION_ID = 'overview';

/**
 * Product Q&A Section ID
 */
export const PRODUCT_QA_SECTION_ID = 'product-qa';

/**
 * Product Matching Products Section ID
 */
export const MATCHING_PRODUCTS_SECTION_ID = 'matching-products';

/**
 * Product Parts Section ID
 */
export const PRODUCT_PARTS_SECTION_ID = 'product-parts';

/**
 * PDP Buysection ID
 */
export const PDP_BUYSECTION_ID = 'pdp-buysection';

/**
 * PDP Product Config ID
 */
export const PRODUCT_CONFIG_SECTION_ID = 'pdp-configuration';

/**
 * PDP Product Shop By Looks ID
 */
export const SHOP_BY_LOOKS_SECTION_ID = 'product-shop-by-look';

/**
 * Enum to support cookie control of dy
 */
export enum PdpDyPlacementEnum {
	VARIATION_VERTICAL = 'variation-vertical',
	VARIATION_TWO = 'variation-2',
	VARIATION_THREE = 'variation-3',
	CONTROL = 'control'
}
