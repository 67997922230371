import { type FunctionComponent } from 'react';
import { type ContentCardCommonFieldsFragment } from '../../../__generated__/graphql-client-types';
import { MaybeLink } from '../../common-components/link/link.component';
import { PartialWidthGraphImage } from '../../images/graph-images/partial-width-graph-image/partial-width-graph-image';

export type ImageContentCardProps = ContentCardCommonFieldsFragment & { perRow?: number };
export const ImageContentCard: FunctionComponent<ImageContentCardProps> = ({ image, url, title, perRow }) => {
	return (
		<MaybeLink url={url}>{image && <PartialWidthGraphImage image={image} caption={title ?? undefined} perRow={perRow} />}</MaybeLink>
	);
};
