import type { ProductFamily } from '../../../types/product.types';
import { TrackedEvent, TrackedEventCase } from '../event-types';
import type { GTMCustomEvent, GTMEventWrapper, GTMViewProductEvent } from './gtm-types';
import { type ProductVariant, wrapGTMEvent, buildGTMProductDetails } from './gtm-utils.helper';

/*
 * Builds a GTM event for viewing a product details page
 */
export const buildGTMViewProductDetails = (variant: ProductVariant, productFamily: ProductFamily): GTMEventWrapper<GTMViewProductEvent> => {
	return wrapGTMEvent(TrackedEvent.PRODUCT_DETAIL_VIEW, TrackedEventCase.PRODUCT_DETAIL_LOAD, {
		detail: {
			products: [buildGTMProductDetails(variant, productFamily)]
		}
	});
};

/*
 * Builds a GTM event for opening the media gallery
 */
export const buildGTMMediaGalleryOpened = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.MEDIA_GALLERY_MODAL_OPENED
});

/**
 * Builds a GTM event for selecting a variant
 */
export const buildGTMSelectVariant = (variant: ProductVariant, productFamily: ProductFamily): GTMEventWrapper<GTMViewProductEvent> => {
	return wrapGTMEvent(TrackedEvent.PRODUCT_CLICK, TrackedEventCase.VARIANT_SELECT, {
		detail: {
			products: [buildGTMProductDetails(variant, productFamily)]
		}
	});
};

/**
 * Builds a GTM event for tracking reviews interactions
 */
export const buildGTMReviewsInteractionClick = (variationInteraction: string): GTMCustomEvent => {
	return {
		event: TrackedEvent.PRODUCT_PAGE_EVENTS,
		type: TrackedEventCase.REVIEWS_INTERACTION,
		variationInteraction
	};
};

export const buildGTMReviewsBuySectionLinkClick = (): GTMCustomEvent => {
	return {
		event: TrackedEvent.PRODUCT_PAGE_EVENTS,
		type: TrackedEventCase.REVIEWS_BUY_SECTION_LINK_CLICKED
	};
};

export const buildProductQuantityChange = (isNested: boolean): GTMCustomEvent => ({
	event: TrackedEvent.PRODUCT_PAGE_EVENTS,
	type: TrackedEventCase.QUANTITY_CHANGE,
	input: isNested ? 'nested-product-quantity' : 'main-quantity'
});

export const buildGTMAddToCartPdpMediaGalleryReorder = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.ADD_TO_CART_PDP_MEDIA_GALLERY_REORDER
});

/* Review + Q&A */
export const buildGTMReviewsExpanded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.REVIEWS_EXPANDABLE_OPENED
});

export const buildGTMQAExpanded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.QA_EXPANDABLE_OPENED
});

export const buildGTMReviewsLoaded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.REVIEWS_LOADED
});

export const buildGTMQALoaded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.QA_LOADED
});
