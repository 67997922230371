import { type FunctionComponent, type PropsWithChildren, useContext } from 'react';
import { ConstructContext } from '../../contexts/construct/construct.context';
import { StyledButton } from '../buttons';
import { Link } from '../common-components/link/link.component';

export type ContentContainerProps = {
	contentId: string;
};

export const ContentContainer: FunctionComponent<PropsWithChildren<ContentContainerProps>> = ({ contentId, children }) => {
	const { highlightContent, constructHost } = useContext(ConstructContext);
	return (
		<div className={`relative ${highlightContent ? 'shadow-5 bl b--theme-primary bw2' : ''}`}>
			{highlightContent && (
				<div className="absolute right-0 top-0 shadow-2 z-1">
					<Link url={`${constructHost}/item/${contentId}`} openInNewTab={true}>
						<StyledButton>Edit in Construct</StyledButton>
					</Link>
				</div>
			)}
			{children}
		</div>
	);
};
