import { type CardContent, type ContentComponent } from '../../../../types/construct.types';
import { Placeholder } from '../../../common-components/placeholder/placeholder.component';
import { ArticleContentCard } from '../../article-content-card/article-content-card.component';
import { CategoryContentCard } from '../../category-content-card/category-content-card.component';
import { ImageContentCard } from '../../image-content-card/image-content-card.component';
import { ProductContentCard } from '../../product-content-card/product-content-card.component';
import { SaleCategoryContentCard } from '../../sale-category-content-card/sale-category-content-card.component';
import { VideoContentCard } from '../../video-content-card/video-content-card.component';

const isSalesSection = (schemaType: string) => /^(deals-template|savings-center|sitewide-sales-template)/.test(schemaType);

export const ContentCardHandler: ContentComponent<CardContent> = ({ content, options, renderer }) => {
	// Product and category cards render differently in sales sections.
	const forSalesSection = isSalesSection(renderer.schemaType);

	switch (content.cardType) {
		case 'CATEGORY':
			return forSalesSection ? <SaleCategoryContentCard {...content} /> : <CategoryContentCard {...content} />;
		case 'IMAGE':
			return <ImageContentCard {...content} perRow={options?.GroupItemContent?.totalItemCount} />;
		case 'PRODUCT':
			return <ProductContentCard cardContent={content} isSaleCard={forSalesSection} />;
		case 'ARTICLE':
			return <ArticleContentCard {...content} rootSchema={renderer.schemaType} />;
		case 'VIDEO':
			return <VideoContentCard {...content} options={options} />;
		case 'BRAND':
		case 'EXTERNAL':
		case 'FAVORITE':
		case 'INTERNAL':
		case 'PAGE':
		case 'PROFILE':
		default:
			return <Placeholder>{content.cardType} Card Not Implemented Yet</Placeholder>;
	}
};
