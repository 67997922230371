import { type ImageFieldsFragment } from '../../../../__generated__/graphql-client-types';
import { type ContentComponent, type InternalAuthor, type ProfileCardContent } from '../../../../types/construct.types';
import { ProfileGraphImage } from '../../../images/graph-images/profile-graph-image/profile-graph-image.component';
import { addContentHandler } from '../add-content-handler';

const isInternalAuthor = (author): author is InternalAuthor => 'profile_id' in author;

export const ContentAuthorHandler: ContentComponent<ProfileCardContent> = ({ content: { fields } }) => {
	let avatar: ImageFieldsFragment | undefined | null;
	let name: string | undefined | null;
	let isGuestAuthor = false;
	if (isInternalAuthor(fields)) {
		const [selectedProfile] = fields.profile_id.items;
		if (selectedProfile?.detail?.profile) {
			const { profile } = selectedProfile.detail;
			({ avatar, name } = profile);
		}
	} else {
		isGuestAuthor = true;
		const guestAuthor = fields.guest_author.fields;
		({
			full_name: name,
			profile_image: { image: avatar }
		} = guestAuthor);
	}
	if (name) {
		return (
			<div className="flex items-center ga2 mb3">
				{avatar && <ProfileGraphImage image={avatar} size={56} />}
				<div className="lh-copy f7">
					<div>{name}</div>
					{isGuestAuthor && <div>Guest Author</div>}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

addContentHandler('content-author', 'ObjectContent', 'content-author@1', ContentAuthorHandler);
