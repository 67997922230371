import { type FunctionComponent, type PropsWithChildren } from 'react';
import { type ContentComponent, type ListContent } from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';
import { tileSectionGap } from './content-list.handler.css';

export const ListContentHandler: ContentComponent<ListContent> = ({ content, renderer, options }) => {
	const contentSections = content.items.filter((item) => Boolean(item && typeof item !== 'string' && item.kind === 'content-section@1'));
	const lastContentSection = contentSections[contentSections.length - 1];

	return (
		<ListContentWrapper layout={options?.CardContent?.layout}>
			{content.items.map((item, itemIndex) =>
				renderer.render(item, itemIndex, {
					...options,
					ListContent: { lastContentSectionIndex: content.items.indexOf(lastContentSection) }
				})
			)}
		</ListContentWrapper>
	);
};

export const ListContentWrapper: FunctionComponent<PropsWithChildren<{ layout?: string }>> = ({ layout, children }) => {
	const twoColumn = layout === 'two-column';
	const style = twoColumn ? `flex-column-ns flex-wrap ${tileSectionGap}` : 'flex-column flex-wrap-ns';
	return (
		<div className={`flex ${style} flex-row-ns gr3 w-100 content-list`} data-testid="content-list">
			{children}
		</div>
	);
};

addContentHandler('content-common', 'ObjectContent', 'default', ListContentHandler);
