import { type FunctionComponent } from 'react';
import { type ContentCardCommonFieldsFragment } from '../../../__generated__/graphql-client-types';
import { type TypedContentOptions } from '../../../types/construct.types';
import { VideoCard } from '../../common-components/video-card/video-card.component';
import { PlayCircleIcon } from '../../svg/icons.component';
import { RelatedVideo } from '../related-video/related-video.component';

type VideoContentCardProps = ContentCardCommonFieldsFragment & { options?: TypedContentOptions };

const VideoCardTriggerElement: FunctionComponent = () => {
	return (
		<div className="aspect-ratio--object flex justify-center items-center">
			<PlayCircleIcon className="theme-white f1 f-headline-ns" />
		</div>
	);
};

export const VideoContentCard: FunctionComponent<VideoContentCardProps> = ({ detail, options }) => {
	if (detail?.__typename !== 'ContentVideoCardDetail' || !detail.video) {
		return null;
	}

	return options?.ContentSection ? (
		<VideoCard video={detail.video} previewWidthPx={500} previewHeightPx={444} triggerElement={<VideoCardTriggerElement />} />
	) : (
		<RelatedVideo {...detail.video} />
	);
};
