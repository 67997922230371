import { forwardRef } from 'react';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { Label } from '../../common-components/label/label.component';
import { InputMessage } from '../input/input.component';
import { focusWithinStyle } from './text-area.css';

export type TextAreaProps = {
	ariaLabel?: string;
	className?: string;
	invalid?: boolean;
	invalidMessage?: string;
	name?: string;
	testId?: string;
	onBlur?: (data: React.FocusEvent<HTMLTextAreaElement>) => void;
	onChange?: (data: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onFocus?: (data: React.FocusEvent<HTMLTextAreaElement>) => void;
	onKeyDown?: (data: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	placeholder?: string;
	required?: boolean;
	value?: string | number;
	rows?: number;
	automationHook?: string;
	analyticsHook?: string;
	hasLabel?: boolean;
	disableWidthResize?: boolean;
	description?: string;
	readOnly?: boolean;
	maxLength?: number;
};

export type TextAreaWithLabelProps = TextAreaProps & {
	label: string;
	labelClassName?: string;
	id?: string;
};

const getPlaceholderText = (placeholder = '', required = false): string => {
	const requiredSymbol = required ? '*' : '';
	return placeholder ? `${placeholder}${requiredSymbol}` : '';
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(
		{
			ariaLabel,
			className = '',
			invalid,
			invalidMessage,
			name,
			testId = 'textArea',
			onBlur,
			onChange,
			onFocus,
			onKeyDown,
			placeholder,
			required,
			value,
			rows,
			automationHook,
			analyticsHook,
			hasLabel = false,
			disableWidthResize = false,
			description,
			readOnly,
			maxLength
		},
		ref
	) => (
		<div className="relative dib w-100">
			<textarea
				className={`${className} ${focusWithinStyle} input-reset input outline-0 br2 f5 ph3 ba w-100 ${
					invalid ? 'b--theme-error' : 'b--theme-grey-light'
				}`}
				data-testid={testId}
				id={name}
				aria-label={!hasLabel && ariaLabel ? ariaLabel : undefined}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				placeholder={getPlaceholderText(placeholder, required)}
				required={required}
				value={value}
				rows={rows}
				ref={ref}
				data-automation={generateDataSelector('textarea', automationHook)}
				data-analytics={generateDataSelector('textarea', analyticsHook)}
				style={{ resize: disableWidthResize ? 'vertical' : 'both' }}
				readOnly={readOnly}
				maxLength={maxLength}
			/>
			<InputMessage invalidMessage={invalidMessage} invalid={invalid} description={description} />
		</div>
	)
);

export const TextAreaWithLabel = forwardRef<HTMLTextAreaElement, TextAreaWithLabelProps>(
	({ label, id, required, labelClassName, ...props }, ref) => (
		<Label label={label} required={required} id={id} className={labelClassName}>
			<TextArea {...props} ref={ref} hasLabel={true} />
		</Label>
	)
);
